import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
import { ItalicText } from '../../components/italic-text/italic-text';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Das moderne Web ist großartig! Wir können leicht barrierefreie, robuste Modal-Dialoge mit dem
nativen `}<InlineCode mdxType="InlineCode">{`<`}{`dialog`}{`>`}</InlineCode>{`-Element erstellen. Ihr möchtet ein Menü oder einen Tooltip über dem
anderen Seiteninhalt öffnen? Kein Problem! Das HTML-Attribut `}<InlineCode mdxType="InlineCode">{`popover`}</InlineCode>{` verwandelt jedes
Element in ein Popup.`}</p>
    <p>{`In den letzten Monaten habe ich einige Dialoge mit dem
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dialog"
      }}>{`nativen HTML-Element`}</a>{` erstellt. Und im April, als
die `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/Popover_API"
      }}>{`Popover API`}</a>{` endlich browserübergreifend unterstützt
wurde, begann ich mit Popover in verschiedenen Projekten zu experimentieren. Mir wurde schnell klar: Diese Web-Features
erleichtern uns Web-Entwickler:innen das Leben sehr, aber es gibt auch einige Herausforderungen. Vor allem, wenn man
beide Features miteinander kombiniert!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAEEAv/EABQBAQAAAAAAAAAAAAAAAAAAAAL/2gAMAwEAAhADEAAAAZcNBSDGf//EABwQAAICAgMAAAAAAAAAAAAAAAECAAMEMRITIf/aAAgBAQABBQLHo8cAQ7pv6wzco2//xAAWEQADAAAAAAAAAAAAAAAAAAABEBH/2gAIAQMBAT8BgX//xAAWEQADAAAAAAAAAAAAAAAAAAAQESH/2gAIAQIBAT8BrH//xAAcEAAABgMAAAAAAAAAAAAAAAAAAhAREiEBcaH/2gAIAQEABj8Ckd9CnSJsOK6n/8QAGRABAAMBAQAAAAAAAAAAAAAAAQARIVGR/9oACAEBAAE/IQQC8ZHVdbQ6MMLcCPYDNavqZZP/2gAMAwEAAgADAAAAEA8P/8QAFxEBAAMAAAAAAAAAAAAAAAAAARAhMf/aAAgBAwEBPxAUrY//xAAXEQADAQAAAAAAAAAAAAAAAAABEBEx/9oACAECAQE/EAbXF//EABwQAQEAAgMBAQAAAAAAAAAAAAERACExQVFxYf/aAAgBAQABPxAXoteA6K9r5i4g4kFKPpxJ7k3N/cQ3wIKXaN5xgnsQafh/DeJUamrn/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Mehrere übereinander gestapelte Pfannkuchen.",
          "title": "Mehrere übereinander gestapelte Pfannkuchen.",
          "src": "/static/0142204fa0ea08c8e382e41c9faa5b4e/e5166/pexels-rama-khandkar-pancakes.jpg",
          "srcSet": ["/static/0142204fa0ea08c8e382e41c9faa5b4e/f93b5/pexels-rama-khandkar-pancakes.jpg 300w", "/static/0142204fa0ea08c8e382e41c9faa5b4e/b4294/pexels-rama-khandkar-pancakes.jpg 600w", "/static/0142204fa0ea08c8e382e41c9faa5b4e/e5166/pexels-rama-khandkar-pancakes.jpg 1200w", "/static/0142204fa0ea08c8e382e41c9faa5b4e/d9c39/pexels-rama-khandkar-pancakes.jpg 1800w", "/static/0142204fa0ea08c8e382e41c9faa5b4e/df51d/pexels-rama-khandkar-pancakes.jpg 2400w", "/static/0142204fa0ea08c8e382e41c9faa5b4e/d2602/pexels-rama-khandkar-pancakes.jpg 4032w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © Rama Khandkar / pexels.com`}</em></p>
    <p>{`Ich werde kurz darauf eingehen, wie Dialoge und Popovers miteinander interagieren. Dann werde ich meine hart
erarbeiteten Erkenntnisse mit euch teilen. Wenn ihr mit den Features noch nicht vertraut seid, empfehle ich euch, meine
Blogbeiträge `}<a parentName="p" {...{
        "href": "/de/native-dialog-element.de/"
      }}>{`„Warum du das native Dialog-Element nutzen solltest“`}</a>{` und
`}<a parentName="p" {...{
        "href": "/de/popover-api-accessibility.de/"
      }}>{`„Einfach herausragend! Die neue Popover API und CSS Anchor Positioning“`}</a>{` zu lesen.`}</p>
    <h2>{`Die Grundlagen: Wie Dialog- und Popover-Elemente interagieren`}</h2>
    <p>{`Wenn ihr einen Modal-Dialog mit der Methode `}<InlineCode mdxType="InlineCode">{`showModal()`}</InlineCode>{` öffnet, wird das Dialogfeld zum
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Glossary/Top_layer"
      }}>{`Top-Layer`}</a>{` hinzugefügt und über anderen Seiteninhalten
gerendert. Dasselbe geschieht, wenn ihr Popover-Inhalte mit der Methode `}<InlineCode mdxType="InlineCode">{`showPopover()`}</InlineCode>{` anzeigt.`}</p>
    <p>{`Was passiert nun, wenn ein Element im Top-Layer bereits geöffnet ist und ihr ein weiteres Element hinzufügt? Die
Elemente werden in der Reihenfolge übereinander gelegt, in der sie dem Top-Layer hinzugefügt wurden. Das zuletzt
hinzugefügte Element erscheint immer oben. Mit der CSS-Eigenschaft `}<InlineCode mdxType="InlineCode">{`z-index`}</InlineCode>{` könnt ihr diese
Reihenfolge auch nicht verändern. Es zählt allein, wann ein Element dem Top-Layer hinzugefügt wurde.`}</p>
    <p>{`Um ein Beispiel zu geben: Eine Schaltfläche öffnet ein Menüfeld als Popover. Dieses Menü enthält eine Option, die einen
Modal-Dialog öffnet, der über dem Menü-Panel gerendert wird. Dieses Dialogfeld enthält eine Schaltfläche, die ein
Tooltip-Popover über dem Dialogfeld öffnet. Und so weiter, und so fort. 😉`}</p>
    <h2>{`Was ihr über Dialoge und Popovers wissen solltet`}</h2>
    <h3>{`Animation als `}<span lang="en">{`Progressive Enhancement`}</span></h3>
    <p>{`Alles sieht besser aus mit flüssigen Animationen. Es gibt nur ein Problem: Dialoge und Popovers
haben `}<InlineCode mdxType="InlineCode">{`display: none`}</InlineCode>{` gesetzt, wenn sie ausgeblendet sind, und `}<InlineCode mdxType="InlineCode">{`display: block`}</InlineCode>{`,
wenn sie angezeigt werden. Und wir alle wissen, dass man Inhalte nicht von oder zu `}<InlineCode mdxType="InlineCode">{`display: none`}</InlineCode>{`
animieren kann. Richtig? Falsch!`}</p>
    <p>{`Die neue `}<InlineCode mdxType="InlineCode">{`@starting-style`}</InlineCode>{` Regel zusammen mit der CSS-Eigenschaft `}<InlineCode mdxType="InlineCode">{`transition-behavior`}</InlineCode>{`
ermöglicht die Animation von Dialogen und Popovers. Ich habe beide Features in meinem Blogbeitrag
`}<a parentName="p" {...{
        "href": "/de/accessible-popover-alert.de/"
      }}>{`„Barrierefreie Alerts leicht gemacht mit der Popover API“`}</a>{` ausführlich beschrieben.`}</p>
    <p>{`Seht euch meine Demo für einen Modal-Dialog mit sanfter Ein- und Ausblend-Animation an. Zum Zeitpunkt des Verfassens dieses
Artikels funktioniert das nur in Chrome und Edge:`}</p>
    <iframe title="Dialog Animation Demo" src="https://codepen.io/alexlehner86/embed/jOoaZRK?default-tab=result" loading="lazy">
    See the Pen <a href="https://codepen.io/alexlehner86/pen/jOoaZRK">
    Dialog Animation Demo</a> by Alexander Lehner (<a href="https://codepen.io/alexlehner86">@alexlehner86</a>)
    on <a href="https://codepen.io">CodePen</a>.
    </iframe>
    <p>{`Ihr könnt Transitionen für das Dialogfeld (oder Popover) selbst sowie für den Hintergrund definieren. Hier ist der CSS-Code
für die Animation des Dialogs:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`dialog {
    --duration: 150ms;
    --start-opacity: 0.5;
    --start-scale: scale(0.8);

    /* End values for fade out. */
    opacity: var(--start-opacity);
    transform: var(--start-scale);
    transition:
        opacity var(--duration) ease-out,
        transform var(--duration) cubic-bezier(0, 0, 0.2, 1),
        overlay var(--duration) allow-discrete,
        display var(--duration) allow-discrete;
}

dialog[open] {
    /* End values for fade in; start values for fade out. */
    opacity: 1;
    transform: scale(1);

    @starting-style {
        /* Start values vor fade in. */
        opacity: var(--start-opacity);
        transform: var(--start-scale);
    }
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Und so animiert ihr den Hintergrund des Dialogs:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`/* Styling for backdrop behind the dialog */
dialog::backdrop {
    background: rgb(0 0 0 / 0.32);
    /* End value for fade out. */
    opacity: 0;
    transition: opacity var(--duration),
        overlay var(--duration) allow-discrete,
        display var(--duration) allow-discrete;
}

dialog[open]::backdrop {
    /* End value for fade in; start value for fade out. */
    opacity: 1;
}

/* This starting-style rule cannot be nested inside the above selector because the nesting selector cannot represent pseudo-elements. */
@starting-style {
    dialog[open]::backdrop {
        /* Start value vor fade in. */
        opacity: 0;
    }
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Aber was ist mit der Browser-Unterstützung? Keine Sorge! Die `}<InlineCode mdxType="InlineCode">{`@starting-style`}</InlineCode>{` Regel und die
Eigenschaft `}<InlineCode mdxType="InlineCode">{`transition-behavior`}</InlineCode>{` sind perfekte Beispiele für `}<span lang="en">{`Progressive Enhancement`}</span>{`.
Wenn ein Browser die neuen Features nicht unterstützt, wird das Dialog- oder Popover-Element trotzdem gerendert, allerdings
ohne die Animation.`}</p>
    <h3>{`Den Dialog automatisch schließen bei Klick auf den Hintergrund`}</h3>
    <p>{`Das native `}<InlineCode mdxType="InlineCode">{`<`}{`dialog`}{`>`}</InlineCode>{`-Element hat einige tolle Funktionen eingebaut:`}</p>
    <ul>
      <li parentName="ul">{`Wenn ein Modal-Dialog geöffnet wird, setzt der Browser den Fokus auf das erste interaktive Element innerhalb des Dialogs.`}</li>
      <li parentName="ul">{`Beim Schließen des Modal-Dialogs wird der Fokus wieder auf das Element gesetzt, das den Dialog geöffnet hat.`}</li>
      <li parentName="ul">{`Benutzer können den Modal-Dialog mit der `}<InlineCode mdxType="InlineCode">{`ESC`}</InlineCode>{`-Taste schließen.`}</li>
    </ul>
    <p>{`Eine wichtige Funktion wird jedoch standardmäßig nicht unterstützt: Automatisches Schließen des Dialogs, wenn die
Nutzer:innen auf den Hintergrund klicken. In meinem `}<a parentName="p" {...{
        "href": "/de/native-dialog-element.de/"
      }}>{`ersten Blogbeitrag über Dialoge`}</a>{` habe
ich eine benutzerdefinierte Implementierung dieser Funktion gezeigt: Man ermittelt die Koordinaten des Klicks und vergleicht
sie mit dem Rechteck des Dialogs.`}</p>
    <p>{`Vor kurzem bin ich auf eine elegantere Lösung gestoßen: Ihr definiert einen
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener"
      }}>{`Klick-Event-Listener`}</a>{` für den Dialog und
überprüft dann den Tag-Namen des Ereignis-Ziels. Hier ist der JavaScript-Code für die Funktion:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`function onDialogClick(event) {
    event.stopPropagation();
    if (event.target.tagName === "DIALOG") {
        dialogElementRef.close();
    }
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Ein Klick auf den Dialog-Hintergrund wird als Klick auf das Dialogelement registriert. Damit dies funktioniert, muss der
Inhalt des Dialogs von einem zusätzlichen Element umschlossen werden. Andernfalls würde ein Klick in bestimmte Bereiche des
Dialogs diesen auch schließen.`}</p>
    <p>{`Schaut euch die CodePen-Demo oben an, in der ich dieses benutzerdefinierte Verhalten ebenfalls implementiert habe.`}</p>
    <h3>{`Wie ihr Popover-Inhalte korrekt in Modal-Dialogen verschachtelt`}</h3>
    <p>{`Unlängst habe ich für einen Kunden an einem Webprojekt gearbeitet, das eine Liste von Elementen enthält. Die Nutzer:innen
können auf eines der Elemente klicken, um einen Modal-Dialog mit weiteren Details zu öffnen. Dieser Dialog enthält eine
Schaltfläche, mit der man das Element zur Favoritenliste hinzufügen kann. Dann wird am unteren Rand des Bildschirms eine
`}<a parentName="p" {...{
        "href": "https://m3.material.io/components/snackbar/overview"
      }}>{`Snackbar`}</a>{` angezeigt, die auch eine Rückgängig-Schaltfläche enthält.`}</p>
    <p>{`Als hipper, moderner Webentwickler wollte ich natürlich das `}<InlineCode mdxType="InlineCode">{`<`}{`dialog`}{`>`}</InlineCode>{`-Element in
Kombination mit einem `}<InlineCode mdxType="InlineCode">{`popover`}</InlineCode>{`-Element für die Snackbar verwenden. Nachdem ich alles implementiert
hatte, begann ich, die Rückgängig-Funktion zu testen und war verblüfft: Obwohl die Snackbar mit der Rückgängig-Schaltfläche
sichtbar war und über dem Modal-Dialog angezeigt wurde, konnte ich nicht mit ihr
interagieren. Ich so: `}<ItalicText mdxType="ItalicText">{`„Was zum Teufel ist hier los?!“`}</ItalicText></p>
    <p>{`Nach etwas Recherche habe ich die Ursache des Problems gefunden. Aber bevor ich euch die Lösung verrate, seht euch diese
Demo mit einer Minimalversion des oben beschriebenen Webprojekts an. Der Dialog enthält zwei Schaltflächen: Die erste öffnet
eine Snackbar, mit der ihr nicht interagieren könnt. Die zweite Schaltfläche öffnet eine Snackbar mit einer funktionierenden
Rückgängig-Taste. Könnt ihr den Unterschied erkennen?`}</p>
    <iframe title="Dialog with Popover Demo" src="https://codepen.io/alexlehner86/embed/mdYqZdW?default-tab=result" loading="lazy">
    See the Pen <a href="https://codepen.io/alexlehner86/pen/mdYqZdW">
    Dialog with Popover Demo</a> by Alexander Lehner (<a href="https://codepen.io/alexlehner86">@alexlehner86</a>)
    on <a href="https://codepen.io">CodePen</a>.
    </iframe>
    <p>{`Ich erkläre euch was los ist: Im Allgemeinen hat die Position eines `}<InlineCode mdxType="InlineCode">{`popover`}</InlineCode>{`-Elements im DOM keinen
Einfluss auf seine Sichtbarkeit. Wenn ihr das Popover einblendet, fügt der Browser es dem Top-Layer hinzu und es erscheint
über allen anderen Inhalten. Aber nur weil ihr etwas sehen könnt, heißt das nicht, dass ihr damit interagieren könnt. Die
Antwort findet sich in der
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/HTMLDialogElement/showModal"
      }}>{`Beschreibung der Methode showModal()`}</a>{`:`}</p>
    <blockquote lang="en">
    The showModal() method of the HTMLDialogElement interface displays the dialog as a modal [...]. Interaction outside the
    dialog is blocked and <strong>the content outside it is rendered inert</strong>.
    </blockquote>
    <p>{`Das heißt, wenn ihr das `}<InlineCode mdxType="InlineCode">{`popover`}</InlineCode>{`-Element für die Snackbar außerhalb des Dialogs platziert, wird
es inaktiv gemacht. Alle Eingabe-Events der Nutzer:innen für das Element und seine Inhalte werden ignoriert. Was könnt ihr
also tun? Platziert das Popover-Element einfach innerhalb des Dialogelements. Jetzt betrachtet der Browser es als Teil des
Dialoginhalts und macht es nicht mehr inaktiv.`}</p>
    <h3>{`Manuelle Popovers innerhalb von Dialogen solltet ihr immer schließen`}</h3>
    <p>{`Eine letzte Bemerkung zu manuellen Popovers, die in einem Modal-Dialog eingebettet sind. Wenn ihr den Dialog schließt,
dann schließt auch immer das Popover mit der Methode `}<InlineCode mdxType="InlineCode">{`hidePopover()`}</InlineCode>{`. Andernfalls wäre das Popover
zwar nicht sichtbar, aber immer noch offen und würde im Top-Layer verbleiben.`}</p>
    <p>{`Wenn ihr nun den Dialog erneut öffnet, wird er über dem Popover im Top-Layer platziert. Selbst wenn ihr also versucht, das
Popover erneut mit der Methode `}<InlineCode mdxType="InlineCode">{`showPopover()`}</InlineCode>{` zu öffnen, wird nichts passieren! Das Popover ist
bereits geöffnet und befindet sich im Top-Layer unterhalb des Modal-Dialogs. Was für ein Schlamassel!`}</p>
    <h2>{`Fazit`}</h2>
    <p>{`Das native `}<InlineCode mdxType="InlineCode">{`<`}{`dialog`}{`>`}</InlineCode>{`-Element und das `}<InlineCode mdxType="InlineCode">{`popover`}</InlineCode>{`-Attribut sind sehr
mächtige und praktische Features. Aber es gibt auch einige Besonderheiten. Ich hoffe, meine Erkenntnisse helfen euch dabei,
diese großartigen Features in euren eigenen Projekten zu nutzen. Viel Spaß beim Coden! 😊`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      